const validate = (values) => {
  var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

  const error = {};

  // Validate Name
  if (!values.name) {
    error.name = "Name is required";
  } else if (values.name.length < 1) {
    error.name = "Invalid name";
  }

  // Validate Mobile number
  // if (!values.mobile) {
  //   error.mobile = "Mobile number is required";
  // } else if (!values.mobile.match(phoneno)) {
  //   error.mobile = "Invalid mobile number";
  // }
  if (!values.mobile) {
    error.mobile = 'Mobile Number is required';
  } else if (!/^\d{10}$/.test(values.mobile)) {
    error.mobile = 'Invalid Mobile Number';
  }


  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    error.email = "Invalid email address";
  }
  if (!values.type || values.type == "Select") {
    error.type = "Please select your catoriry";
  }

  return error;
};

export default validate;
