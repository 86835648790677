import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Nav from"./Nav";
import { useNavigate} from 'react-router-dom';
function Crampcure() {
  const navigate = useNavigate();
  return (
    <div className="App" id="Environment">
      <Nav/>
      {/* <div class="mx-auto p-5 space-x-10 mb-10 justify-items-center  text-center ">
        <button class="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Environment
        </button>
        <button class="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Disinfect
        </button>
        <button class="hover:bg-orange-500 border border-cyan-600 px-4 py-2 text-cyan-600 hover:border-orange-500 hover:text-white rounded-full ">
          Health
        </button>
      </div> */}
      {/* <section class="text-gray-600 body-font xl:px-10">
        <div class="container px-5 py-2 mx-auto flex flex-wrap ">
          <div class="flex flex-wrap md:-m-2 -m-1">
       
            <div class="flex flex-wrap md:w-1/2 rounded-2xl shadow-lg ">
              <div class="md:p-2 p-1 w-full ">
                <div class="bg-white  p-10 ">
                  <img class="mx-auto " src="images/Dr_Grow_Alkasense (1).png" />
                </div>
              </div>
           
            </div>
            <div class="lg:flex-grow  md:w-1/2  flex flex-col md:items-start md:text-left mb-16 md:mb-0 rounded-2xl shadow-lg  ">
              <div class="md:p-2 p-1 w-full">
                <div class="bg-white p-10 ">
                  <h1 class="text-3xl xl:text-4xl font-bold text-gray-700">
                  Alkasense
                  </h1>
                  <h4 class="text-xl  pb-3 py-2 text-gray-700 font-semibold py-4">
                  Sodium Bicarbonate for Aquaculture Use
                   
                  </h4>
                  <div class="  pb-2">
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    pH Control
                    </span>
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Alkalinity 
                    </span>
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Water Quality
                    </span>
                  </div>
                  <h2 class="text-2xl pt-3 font-bold text-gray-700 ">
                  Contains
                  </h2>
                  <p class="pb-3">Sodium Bicarbonate - 99%</p>
                  <h2 class="text-2xl  font-bold text-gray-700 ">Benefits</h2>
                  <p class="">
                  <ol>
  <li><span class="text-3xl">&#8226; &nbsp;</span>Increases the alkalinity of the pond water.</li>
  <li><span class="text-3xl">&#8226; &nbsp;</span>Maintains the pH levels.</li>
  <li><span class="text-3xl">&#8226; &nbsp;</span>Contributes to the shrimp’s health and stress reduction. </li>
  <li class=""><span class="text-3xl">&#8226; &nbsp;</span>Removes bad odour from ponds.
  </li>
  
</ol>
 </p>
                  <h2 class="text-2xl  font-bold text-gray-700 mt-5" >Dosage</h2>
                  <p class="">
                  7-8 kg/acre or As advised by an aquaculture technician.
                  </p>
                

                  <h2 class="text-2xl pt-3 font-bold text-gray-700 mt-5">
                  Application Method
                  </h2>
                  <p class="pb-3">Apply directly or mix with dry sand and broadcast throught the pond.</p>
  </div>
              </div>
            </div>

          </div>
        </div>
      </section> */}
<section className="text-gray-600 body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex ">
          <div className="flex w-full md:-m-2 -m-1">
         
            <div className="flex-1 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img className="mx-auto max-h-full" src="images/Dr_Grow_Alkasense (1).png" alt="Alkasense" />
              </div>
            </div>

        
            <div className="flex-1 md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold text-gray-700 py-5">
                Alkasense
                </h1>
                <h4 className="text-xl pb-3 text-gray-700 font-semibold">
                Sodium Bicarbonate for Aquaculture Use
                </h4>
                <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                  pH Control
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                  Alkalinity 
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                  Water Quality
                  </span>
                </div>

                 <h2 className="text-2xl font-bold text-gray-700">Contents</h2>
                <p className="pb-3">
                Sodium bicarbonate - 99%.
                </p> 

                <h2 className="text-2xl font-bold text-gray-700 mt-4">Benefits</h2>
                <ul>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Increases the alkalinity of the pond water.</li>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Maintains the pH level. </li>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Contributes to the shrimp’s health and stress reduction.
                  </li>
      <li><span className="text-3xl">&#8226; &nbsp;</span>Removes bad odour from ponds.

</li>
                </ul>

                <h2 className="text-2xl font-bold text-gray-700 mt-5">Dosage</h2>
                <p>
                7-8 kg/acre or As advised by an aquaculture expert.
                </p>
                <h2 class="text-2xl pt-3 font-bold text-gray-700 mt-5">
                  Application Method
                  </h2>
                  <p class="pb-3">Apply directly or mix with dry sand and broadcast throughout the pond.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="">
        <h2 class="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-10 2xl:px-44">Other Products </h2>
      </div>
      <div class="flex flex-wrap xl:px-10 2xl:px-40">
        <div class="md:flex sm:w-1/2 lg:w-1/4  xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/Teaseedpowder")}>
            <img class="mx-auto" src="images/Dr_Grow_Tea_Seed_Powder (1).png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
            Tea seed Powder
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Wild Fishes
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Crabs
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Snails
              </span>
            </div>
           
          </div>
        </div>
        <div class="md:flex sm:w-1/2  lg:w-1/4 xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"onClick={() => navigate("/Aquapromsl")}>
            <img  class="mx-auto" src="images/Dr_Grow_Aqua_Pro-MSL.png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
            Aqua Pro-MSL
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2 ">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Green Colonies
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Toxic Gases
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              All-Weather
              </span>
            </div>
          </div>
        </div>

        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Gutbot")}
          >
            <img
              onClick={() => navigate("/Gutbot")}
              class="mx-auto"
              src="images/Dr_Grow_Gut_Bot 1.png"
              alt=""
            />

            <div>
            
              <h3 class="text-xl font-bold text-gray-700  sm:text-center mb-2">
                <span class="lust ">Gut Bot</span>
              </h3>

              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Digestion
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span>
              
            </div>
          </div>
        </div>

        

        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Ecobot")}
          >
            <img
              onClick={() => navigate("/Ecobot")}
              class="mx-auto"
              src="images/Dr_Grow_Eco_Bot 1.png"
              alt=""
            />

            <div>
            
              <h3 class="text-xl font-bold text-gray-700  sm:text-center mb-2">
                <span class="lust ">Eco Bot</span>
              </h3>

              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Organic Loads
              </span>
              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span>
              
            </div>
          </div>
        </div>

        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Zeoforce")}
          >
            <img
              onClick={() => navigate("/Zeoforce")}
              class="mx-auto"
              src="images/Dr_Grow_Zeo-Force (1).png"
              alt=""
            />

            <div>
            
              <h3 class="text-xl font-bold text-gray-700  sm:text-center mb-2">
                <span class="lust ">Zeo-Force</span>
              </h3>

              <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Improves Do
              </span>
              <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Removal
              </span>
              <span
                onClick={() => navigate("/Zeoforce")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Ideal Water Quality
              </span>
              
            </div>
          </div>
        </div>
        {/* <div class="md:flex sm:w-1/2 lg:w-1/4  p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg">
            <img class="mx-auto" src="images/Vibrio.png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
              Vibrio Terminator
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2">
              <span class="inline-block  bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Vibrio
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                HarmfulPathogens
              </span>
            </div>
          </div>
        </div>
        <div class="md:flex sm:w-1/2 lg:w-1/4 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg">
            <img class="mx-auto" src="images/Vibrio.png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
              Vibrio Terminator
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Vibrio
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Ammonia
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Nitrate
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Crampcure;
