import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Navbar } from "react-bootstrap";
import Nav from "./Nav"
import { useNavigate} from 'react-router-dom';
import ReactDOM from "react-dom/client";
import Crampcure from "./Crampcure";
// import PondBp from "./PondBP";
function Zeoforce() {
  const navigate = useNavigate();

  return (

    <div className="App" id="Environment">
 <Nav/>
 
      {/* <section class="text-gray-600 body-font py-5 xl:px-10">
        <div class="container px-5 py-2  mx-auto flex flex-wrap">
          <div class="flex flex-wrap md:-m-2 -m-1">
     
            <div class="flex flex-wrap md:w-1/2 bg-white rounded-2xl shadow-lg ">
              <div class="md:p-2 p-1 w-full ">
                <div class="bg-white p-10  ">
                  <img class="mx-auto" src="images/Dr_Grow_Zeo-Force (1).png" />
                </div>
              </div>
             
            </div>
            <div class="lg:flex-grow  md:w-1/2  flex flex-col md:items-start md:text-left mb-16 md:mb-0 bg-white rounded-2xl shadow-lg ">
              <div class="md:p-2 p-1 w-full">
                <div class="bg-white p-10   ">
                  <h1 class="text-3xl xl:text-4xl font-bold text-gray-700">
                  Zeo - Force
                  </h1>
                  <h4 class="text-xl  pb-3 py-2 text-gray-700 font-semibold py-4">
                  Premium zeolite with superior cation exchange capacity
                   
                  </h4>
                  <div class="  pb-2">
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Vibrio
                    </span>
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Organic Loads 
                    </span>
                    <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                    Toxic Gases
                    </span>
                  </div>

                  <h2 class="text-2xl pt-3 font-bold text-gray-700 ">
                    Contents
                  </h2>
                  <p class="pb-3">SiO2 : 50-60%, Al203: 17-25%, CaO: 15-20%, Na2O : 1-2%, MgO: 2-5%, Fe2O3: 2-5%.
                  </p>

  <h2 class="text-2xl  font-bold text-gray-700 ">Benefits</h2>
                  <p class="">
                  <ol>
  <li><span class="text-3xl">&#8226;</span>Advance Cation Exchange Capacity (CEC). 
  </li>
  <li><span class="text-3xl">&#8226;</span>Effective removal of toxic gases & pollutants. 
  </li>
 
  <li class=""><span class="text-3xl">&#8226;</span>Reduces the release
of toxic gases like ammonia
and H2S.</li>
  <li><span class="text-3xl">&#8226;</span>Improves dissolved oxygen levels. 

  </li>
  <li><span class="text-3xl">&#8226;</span>Stabilizes plankton bloom through optimum photosynthesis process.

  </li>
</ol>
 </p>
                  <h2 class="text-2xl  font-bold text-gray-700 mt-5" >Dosage</h2>
                  <p class="">
                  For Pond Preparation: 50 kg/ha. 
                  </p>
                  <p class="pt-2">
                  During Culture: 25 kg/ha. 
    </p>
                  <p class="pt-2">
                  Modify the dosage, application method, and timing based on culture conditions.
(or as advised by an Aquaculture Expert)
 </p>
    



  </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}


<section className="text-gray-600 body-font xl:px-6">
        <div className="container px-5 xl:px-10 py-2 mx-auto flex flex-wrap">
          <div className="flex flex-wrap md:-m-2 -m-1">
         
            <div className="md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg flex items-center justify-center h-full">
                <img className="mx-auto max-h-full" src="images/Dr_Grow_Zeo-Force (1).png" alt="Zeo-force" />
              </div>
            </div>

        
            <div className="lg:flex-grow md:w-1/2 md:px-2 mb-8">
              <div className="bg-white rounded-2xl shadow-lg p-5 h-full">
                <h1 className="text-3xl xl:text-4xl font-bold text-gray-700 py-5">
                Zeo - Force
                </h1>
                <h4 className="text-xl pb-3 text-gray-700 font-semibold">
                Premium zeolite with superior cation exchange capacity
                   
                </h4>
                <div className="pb-5">
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                  Improves DO
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                 Toxic Removal
                  </span>
                  <span className="inline-block bg-sky-100 border border-cyan-600 rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                 Ideal Water Quality
                  </span>
                </div>

                <h2 className="text-2xl font-bold text-gray-700">Contents</h2>
                <p className="py-3">
                {/* SiO2 : 50-60%, Al203: 17-25%, CaO: 15-20%, Na2O : 1-2%, MgO: 2-5%, Fe2O3: 2-5%. */}

                SiO<sub>2</sub> : 50-60%, Al<sub>2</sub>O<sub>3</sub> : 17-25%, CaO: 15-20%, Na<sub>2</sub>O : 1-2%, MgO: 2-5%, Fe<sub>2</sub>O<sub>3</sub> : 2-5%

                </p>

                <h2 className="text-2xl font-bold text-gray-700 mt-2">Benefits</h2>
                <ul>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Advance Cation Exchange Capacity (CEC). </li>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Effective removal of toxic gases & pollutants.  </li>
                 
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Improves dissolved oxygen levels.</li>
                  <li><span className="text-3xl">&#8226; &nbsp;</span>Stabilizes plankton bloom through optimum photosynthesis process.

</li>
                </ul>

                <h2 className="text-2xl font-bold text-gray-700 mt-5">Dosage</h2>
                <p>
                For Pond Preparation: 50 kg/ha. 
                </p>
                <p class="pt-2">
                During Culture: 25 kg/ha. 

                  </p>
                  <p class="pt-2">
                  Modify the dosage, application method, and timing based on culture conditions.
(or as advised by an aquaculture expert)

                  </p>   
              </div>
            </div>
          </div>
        </div>
      </section>



      <div class=" ">
        <h2 class="text-4xl font-bold ml-2 sm:ml-10 px-5 pt-5 2xl:px-44 "> Other Products</h2>
      </div>
      <div class="flex flex-wrap xl:ml-10 2xl:px-40">
        <div class="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"onClick={() => navigate("/Teaseedpowder")}>
            <img class="mx-auto " src="images/Dr_Grow_Tea_Seed_Powder (1).png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
            Tea Seed Powder
            </h3>
            <div class="px-6 lg:px-3 pt-4 ">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Wild Fishes
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Crabs
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Snails
              </span>
            </div>
          </div>
        </div>
        {/* <div class="md:flex sm:w-1/2 lg:w-1/4 xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer" onClick={() => navigate("/Alkasense")}>
            <img onClick={() => navigate("/Alkasense")}class="mx-auto " src="images/Dr_Grow_Alkasense (1).png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
              Alkasense
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               pH Control
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
                Alkalinity
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
               Water Quality
              </span>
             
            </div>
          </div>
        </div> */}
        <div class="md:flex sm:w-1/2  lg:w-1/4 xl:w-1/3 p-5">
          <div class="bg-white p-10 rounded-2xl shadow-lg cursor-pointer"onClick={() => navigate("/Aquapromsl")}>
            <img  class="mx-auto " src="images/Dr_Grow_Aqua_Pro-MSL.png" />
            <h3 class="text-xl font-bold text-gray-700 text-center">
            Aqua Pro-MSL
            </h3>
            <div class="px-6 lg:px-3 pt-4 pb-2 ">
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Green Colonies
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              Toxic Gases
              </span>
              <span class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2">
              All-Weather
              </span>
            </div>
          </div>
        </div>
        
        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Gutbot")}
          >
            <img
              onClick={() => navigate("/Gutbot")}
              class="mx-auto"
              src="images/Dr_Grow_Gut_Bot 1.png"
              alt=""
            />

            <div>
            
              <h3 class="text-xl font-bold text-gray-700  sm:text-center mb-2">
                <span class="lust ">Gut Bot</span>
              </h3>

              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Gut health
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Digestion
              </span>
              <span
                onClick={() => navigate("/Gutbot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Immunity
              </span>
              
            </div>
          </div>
        </div>
        <div class="md:flex sm:w-1/2 lg:w-1/3 xl:w-1/3 p-5">
          <div
            class="bg-white p-10 rounded-2xl shadow-lg hover:cursor-pointer "
            onClick={() => navigate("/Ecobot")}
          >
            <img
              onClick={() => navigate("/Ecobot")}
              class="mx-auto"
              src="images/Dr_Grow_Eco_Bot 1.png"
              alt=""
            />

            <div>
            
              <h3 class="text-xl font-bold text-gray-700  sm:text-center mb-2">
                <span class="lust ">Eco Bot</span>
              </h3>

              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Vibrio
              </span>
              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
               Organic Loads
              </span>
              <span
                onClick={() => navigate("/Ecobot")}
                class="inline-block  bg-sky-100 border border-cyan-600  rounded-full px-3 py-1 text-sm font-semibold text-cyan-600 mr-2 mb-2"
              >
                Toxic Gases
              </span>
              
            </div>
          </div>
        </div>

</div>   
    </div>
  );
}

export default Zeoforce;
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<PondBp />);