import { useState, useEffect } from "react";
import SearchView from "./search_view/SearchView";
import { Parallax } from "react-parallax";

import { useNavigate} from 'react-router-dom';
import Mode from "./Mode";
// import Carousel from "react-bootstrap/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useFormik } from "formik";
import validate from "./validate";
import Modes from "./Modes";
import Test from "./Test";
import Product from "./Product";
import { HashLink } from "react-router-hash-link";



export default function NavBar({ onSelect }) {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const [issearch, setisSearch] = useState(false);
  const searchClick = () => {
    setisSearch(true);
    onSelect();
  };

  const onTap = () => {
    setisSearch(false);
  };
  return (
    <div>
      <div className="sticky top-0 nav-bar h-[70px] z-10 h ">
        <nav className="w-full bg-gray-100 shadow">
          
          <div className="justify-between px-4  lg:max-w-7xl md:items-center md:flex md:px-14 2xl:mx-40 2xl:max-w-screen-2xl">
            <div>
              <div className="flex items-center justify-between py-3 md:py-5 md:block">
                <a href="/">
                  {/* <h2 className="text-3xl font-bold">Dr.Grow</h2> */}
                 
                  <img class="w-40  " src="/images/Logo RGB - Dr Grow.svg" alt=""/>
                </a>
                <div className="md:hidden">
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div
                className={`flex-1 justify-self-center pb-3  md:block md:pb-0 md:mt-0 ${
                  navbar ? "block" : "hidden"
                }`}
              >
                <ul className="items-center justify-center space-y-2 md:flex md:space-x-6 md:space-y-0">
                  <li className="text-gray-600 hover:text-blue-600">
                   
                       <HashLink class="block mt-4 lg:inline-block lg:mt-0 text-black-100 hover:text-cyan-600 mr-4 text-lg font-semibold"smooth to={'/#OurProducts'}>
                       Our&nbsp;Products
            </HashLink>
                  </li>
                  <li className="text-gray-600 hover:text-blue-600">
                    {/* <a
                      href="javascript:history.go(-2)"
                      class="block mt-4 lg:inline-block lg:mt-0 text-black-100 hover:text-cyan-600 mr-4 text-lg font-semibold"
                    >
                      
                      About&nbsp;Us
                    </a> */}
                     <HashLink class="block  lg:inline-block lg:mt-0 text-black-100 hover:text-cyan-600 mr-4 text-lg font-semibold"smooth to={'/#AboutUS'}>
              About Us
            </HashLink>
                  </li>
                  {/* <li className="text-gray-600 hover:text-blue-600">
                    <a
                      href="javascript:void(0)"
                      class="block mt-4 lg:inline-block lg:mt-0 text-black-100 hover:text-cyan-600 mr-4 text-lg"
                    >
                      Blog
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="#searchview">
                      <img
                        onClick={searchClick}
                        src="/images/search.png"
                        alt="logo"
                        class="block mt-4 lg:inline-block lg:mt-0 "
                      />
                    </a>
                  </li> */}

                  <li className="text-gray-600 hover:text-blue-600">
                   
                     <HashLink class="inline-block bg-orange-500 border border-orange-500 px-4 py-2 text-white hover:border-orange-500 hover:text-orange-500  hover:bg-white rounded-full  mt-2 lg:mt-0 ml-3 font-semibold"smooth to={'/#ContactUs'}>
              Contact Us
            </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    
    

  );
}
