import React, { useState } from "react";
import NavBar from "./components/Navbar";
import Tabs from "./components/Tabs.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PondBp from "./components/PondBP";
import CMB from "./components/CMB";
import Crampcure from "./components/Crampcure";
import ScrollToTop from "./Scrolltotop";
import Alkasense from "./components/Alkasense.js";
import Aquapro from "./components/Aquapro.js";
import Zeoforce from "./components/Dotablet.js";


function App() {
  const [issearch, setisSearch] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const onTap = () => {
    setisSearch(false);
  };

  const onSelect = () => {
    setisSearch(true);
  };


  return (


    
    <div className="absolute w-full">
      {/* {issearch ? (
        <SearchView onTap={onTap} onSearchissearch={issearch} />
      ) : null} */}

      <div
        className={
          issearch
            ? " bg-contain h-full absolute w-full "
            : "opacity-1 bg-contain h-full absolute w-full"
        }
      >
        {/* <Environment /> */}
        {/* <Dotablet /> */}
        {/* <PondBp /> */}
        {/* <Model /> */}
        {/* <NavBar onSelect={onSelect} /> */}
        <BrowserRouter>
      <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<NavBar />} />
            <Route path="/Teaseedpowder" element={<PondBp />} />
            <Route path="/Alkasense" element={<Crampcure />} />
            <Route path="/Aquapromsl" element={<CMB />} />
            <Route path="/Gutbot" element={<Alkasense />} />
            <Route path="/Ecobot" element={<Aquapro />} />
            <Route path="/Zeoforce" element={<Zeoforce />} />

            
            {/* <Route path="/prod/pros" element={<Crampcure />} /> */}
        
            
          </Routes>
          
        </BrowserRouter>
        
        
        {/* <Tabs /> */}
        {/* <Mode /> */}
        
      </div>
      {/* {issearch ? (
        <SearchView className="relative" onTap={onTap} issearch={issearch} />
      ) : null} */}
        

    </div>

    
  );
}

export default App;
